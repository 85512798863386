<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password  -->
      <b-card class="mb-0" v-if="step === 1">
        <!-- logo -->
        <span class="brand-logo">
          <b-img :src="appLogoImage" alt="logo" width="200px" />
        </span>

        <b-card-title class="mb-1"> Esqueceu a senha? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Digite seu email e nós te enviaremos instruções de como recuperá-la
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="exemplo@email.com"
                  @keyup.enter="validationForm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              Enviar link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password -->

      <!-- Confirmation -->
      <b-card class="mb-0" v-if="step === 2">
        <!-- logo -->
        <span class="brand-logo">
          <b-img :src="appLogoImage" alt="logo" width="200px" />
        </span>

        <b-card-title class="p-1 text-center"> Email enviado </b-card-title>
        <b-card-text class="text-center p-0 mb-0">
          Foi enviado um email para <strong>{{ userEmail }}</strong>
        </b-card-text>
        <b-card-text class="text-center p-0">
          Por favor, cheque sua caixa de entrada para acessar o link de
          recuperação. Caso não tenha recebido nada, verifique o lixo eletrônico.
        </b-card-text>
        <b-card-text class="text-center my-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para a página de
            login
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BImg,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { $themeConfig } from '@themeConfig';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'forgot-password',
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
  data() {
    return {
      userEmail: '',
      step: 1,
      // validation
      required,
      email,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch('emailRecoveryPassword', {
              email: this.userEmail.trim(),
              url: `https://vqualityadmin.venxtecnologia.com.br/change-password`,
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email enviado',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              });
              this.step = 2;
            })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao enviar email',
                  text: `Erro: ${err}`,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
